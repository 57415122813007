import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbars/Navbar";
import Banner from "../components/Banner/Banner";
import Insurances from "../components/Banner/Insurances";
import Footer from "../components/Footers/Footer";
// import ReactGA from "react-ga4";

export default function Index() {
  // const [buttonColor, setBottonColor] = useState("primary-black");
  const [size, setSize] = useState(window.innerWidth);
  const [changeColor, setChangeColor] = useState(false);
  const newClass = "main-button-inversed";

  const listenScrollEvent = () => {
    if (window.scrollY >= (size * 40) / 100) {
      // setBottonColor("secondary-light-green");
      setChangeColor(true);
    } else {
      // setBottonColor("primary-black");
      setChangeColor(false);
    }
  };

  const checkSize = () => {
    setSize(window.innerWidth);
  };

  useEffect(() => {
    // console.log(changeColor);
    // ReactGA.pageview(window.location.pathname);
    window.addEventListener("scroll", listenScrollEvent);
    window.addEventListener("resize", checkSize);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
      window.removeEventListener("resize", checkSize);
    };
  });
  return (
    <div className="large-screen-wrapper">
      <div className="main">
        <Navbar activateSecondClass={changeColor} secondClassName={newClass} />
        <div className="main-container">
          <Banner />
          <Insurances />
        </div>
        <div className="main-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
