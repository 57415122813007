import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="footer-links-container">
          <div className="footer-links">
            <div className="footer-links-img">
              <img
                src="img/logo-slogan-md-black.png"
                alt="Logo et slogan AGP Assurance MD"
              />
            </div>

            <div className="footer-links-title">
              <p>
                Notre cabinet a des liens financiers avec l’assureur Intact
                Assurance et nous faisons
              </p>
              <p>principalement affaire avec lui.</p>
            </div>

            <div className="footer-links-connexion">
              <div className="footer-links-connexion-intact footer-links-animation">
                <a href="https://apps.intactinsurance.com/waa/?province=qc&language=fr.">
                  <p>Espace client Intact</p>
                  <img src="img/ext_logo.png" alt="agp assurance" width="20" />
                </a>
              </div>

              <div className="footer-links-connexion-agp footer-links-animation">
                <a href="tel:18664642424">
                  <p>1 866 629-3686</p>
                  <img
                    src="img/phone_logo.png"
                    alt="agp assurance phone"
                    width="25"
                  />
                </a>
              </div>
            </div>

            <div className="footer-links-social">
              <div className="footer-links-social-general">
                <a
                  className="facebook"
                  href="https://www.facebook.com/agpassuranceqc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="nodrag"
                    src="img/facebook.png"
                    alt="facebook"
                    width="25"
                  />
                </a>
              </div>
              <div className="footer-links-social-general">
                <a
                  className="instagram"
                  href="https://www.instagram.com/agpassurance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="nodrag"
                    src="img/instagram.png"
                    alt="instagram"
                    width="45"
                  />
                </a>
              </div>
              <div className="footer-links-social-in">
                <a
                  className="linkedin"
                  href="https://www.linkedin.com/company/agp-assurance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="nodrag"
                    src="img/linkedin.png"
                    alt="linkedin"
                    width="45"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="footer-reclamation">
            <p className="footer-reclamation-title">Réclamations</p>

            <div className="footer-reclamation-assureurs intact">
              <img
                className="logo-assureurs"
                src="img/intact.png"
                alt="Intact logo agp assurance noir"
              />
              <div className="telephone-assureurs">
                <a href="tel:18664642424">
                  <p>1 866 464-2424</p>
                  <img
                    src="img/phone_logo.png"
                    alt="agp assurance phone"
                    width="25px"
                  />
                </a>
              </div>
            </div>

            <div className="footer-reclamation-assureurs unique">
              <img
                className="logo-assureurs"
                src="img/unique.png"
                alt="Intact logo agp assurance noir"
              />
              <div className="telephone-assureurs">
                <a href="tel:14186632711">
                  <p>1 418 663-2711</p>
                  <img
                    src="img/phone_logo.png"
                    alt="agp assurance phone"
                    width="25px"
                  />
                </a>
              </div>
            </div>

            <div className="footer-reclamation-assureurs pafco">
              <img
                className="logo-assureurs"
                src="img/pafco.png"
                alt="Intact logo agp assurance noir"
              />
              <div className="telephone-assureurs telephone-pafco">
                <a href="tel:15143564111">
                  <p>1 514 356-4111</p>
                  <img
                    src="img/phone_logo.png"
                    alt="agp assurance phone"
                    width="25px"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-polique-container">
          <p className="politique-left-title">
            <a
              className="politique-left-animation"
              href="/politique-de-confidentialite"
            >
              POLITIQUE DE CONFIDENTIALITÉ
            </a>
          </p>
          <p className="politique-right-title">
            COPYRIGHT © {new Date().getFullYear()} AGP ASSURANCE TOUS DROITS
            RÉSERVÉS
          </p>
        </div>
      </div>

      <div className="footer-mobile">
        <div className="footer-mobile-top">
          <img
            className="footer-mobile-top-logo"
            src="img/logo-slogan-md-black.png"
            alt="Logo et slogan AGP Assurance MD"
          />

          <div className="footer-mobile-top-links">
            <div className="footer-mobile-top-links-agp">
              <a href="tel:18664642424">
                <p>1 866 629-3686</p>
                <img
                  src="img/phone_logo.png"
                  alt="agp assurance phone"
                  width="25"
                />
              </a>
            </div>

            <div className="footer-mobile-top-links-reclamation">
              <a href="/reclamations">
                <p>Faire une réclamation</p>
              </a>
            </div>

            <div className="footer-mobile-top-links-intact">
              <a href="https://apps.intactinsurance.com/waa/?province=qc&language=fr.">
                <p>Espace client Intact</p>
                <img src="img/ext_logo.png" alt="agp assurance" width="20" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-mobile-middle">
          <div className="footer-mobile-middle-links">
            <div className="footer-mobile-middle-links-general">
              <a
                className="facebook"
                href="https://www.facebook.com/agpassuranceqc"
              >
                <img src="img/facebook.png" alt="facebook" width="25" />
              </a>
            </div>
            <div className="footer-mobile-middle-links-general">
              <a
                className="instagram"
                href="https://www.instagram.com/agpassurance"
              >
                <img src="img/instagram.png" alt="instagram" width="45" />
              </a>
            </div>
            <div className="footer-mobile-middle-links-in">
              <a
                className="linkedin"
                href="https://www.linkedin.com/company/agp-assurance"
              >
                <img src="img/linkedin.png" alt="linkedin" width="45" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-mobile-bottom">
          <div className="footer-mobile-bottom-politique">
            <p>
              <a href="/politique-de-confidentialite">
                POLITIQUE DE CONFIDENTIALITÉ
              </a>
            </p>
          </div>
          <div className="footer-mobile-bottom-text">
            <p>
              Copyright © {new Date().getFullYear()} AGP Assurance. Tous droits
              réservés. Notre cabinet a des liens financiers avec l’assureur
              Intact Assurance et nous faisons principalement affaire avec lui.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
