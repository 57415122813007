import React, { useState, useRef, useEffect, useContext } from "react";
import Modal from "../Modal/modal";

import { ModalContext } from "../../contexts/modalContext";

const Banner = () => {

  const { submissionModal, setSubmissionModal } = useContext(ModalContext)

  const mainCircle = useRef(null);

  const toggleModal = () => {
    setSubmissionModal(true)
  };

  const onMouseOut = (event) => {
    let reset = `translate(0px, 0px)`;

    document.documentElement.style.setProperty("--transformation", reset);
  };

  const onMouseOver = (event) => {
    const position = mainCircle.current.getBoundingClientRect();
    const x = event.pageX - position.left - position.width / 2;
    const y = event.pageY - position.top - position.height / 2;
    let newPosition = "translate(" + x * 0.5 + "px, " + y * 0.5 + "px)";

    document.documentElement.style.setProperty("--transformation", newPosition);
  };

  useEffect(() => {
    const element = mainCircle.current;

    element.addEventListener("mousemove", onMouseOver);
    element.addEventListener("mouseout", onMouseOut);

    return () => {
      element.removeEventListener("mousemove", onMouseOver);
      element.removeEventListener("mouseout", onMouseOut);
    };
  }, []);

  return (
    <section id="main-banner">
      <div className="main-title noselect">
        <div className="title">
          <h1>L'assurance</h1>
          <h1>des gens</h1>
          <h1>passionnés</h1>
        </div>
        <img
          className="avatar-blue nodrag"
          src="img/complete_1.png"
          alt="agp assurance avatar 1"
        />
        <img
          className="avatar-orange nodrag"
          src="img/complete_2.png"
          alt="agp assurance avatar 1"
        />
        <img
          className="avatar-green nodrag"
          src="img/complete_4.png"
          alt="agp assurance avatar 1"
        />
      </div>
      <div className="pointer"></div>

      <div className="main-circle" ref={mainCircle}>
        <div className="split" />
        <div className="circle" onClick={toggleModal}>
          <div className="circle-title noselect">
            <h2>Demandez une</h2>
            <h2>soumission</h2>
          </div>
        </div>
      </div>
      {submissionModal && <Modal />}
      <div className="break-line" />
    </section>
  );
};

export default Banner;
