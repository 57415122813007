import {createContext, useEffect, useState} from "react";
import {ApiClient} from "../../contexts/apiContext";
import {Schedule} from "../../contexts/models/Schedule";

/**
 * @param {[Schedule]} schedulesCC
 * @param {[Schedule]} schedulesAP
 * @param {[Schedule]} schedulesEntreprise
 * @constructor
 */
function SchedulesState(schedulesCC, schedulesAP, schedulesEntreprise) {
    this.schedulesCC = schedulesCC;
    this.schedulesAP = schedulesAP;
    this.schedulesEntreprise = schedulesEntreprise;
}

const ScheduleContext = createContext(new SchedulesState());

const ScheduleProvider = ({children}) => {
    const [schedules, setSchedules] = useState(new SchedulesState())

    useEffect(() =>{
        (async () => {
            let schedules = await ApiClient.fetchSchedules(30) // 30
            const schedulesState = new SchedulesState(
                schedules.schedulesCC,
                schedules.schedulesAP,
                schedules.schedulesEntreprise
            )
            setSchedules(schedulesState)
        })()

    }, [])


    return <ScheduleContext.Provider value={schedules}>
        {children}
    </ScheduleContext.Provider>

}

export {ScheduleContext, ScheduleProvider}
