import React from "react";

export function Option(value, label) {
    return {value, label}
}


export function SelectField({
                                options,
                                label,
                                required,
                                name,
                                handle,
                                status,
                                placeholder,
                                description,
                                errorMessage,
                                type,
                                ...CustomInputFieldProps
                            }) {



    return (
        <div className="field main-text-fields">
            <label>
                {required ? (
                    <h1>{label}<span className="required"> *</span><span className="description">{description}</span>
                    </h1>
                ) : (
                    <h1>{label}<span className="description">{description}</span></h1>
                )
                }
            </label>

            <select {...CustomInputFieldProps}>
                {
                    options.map(({value, label, disable}, index) => <option
                        defaultValue={false}
                        selected={disable ?? false}
                        disabled={disable ?? false}
                        key={index}
                        value={value}>{label}</option>)
                }
            </select>
            {errorMessage ? (<h1 className="error-message">{errorMessage}</h1>) : (<></>)}
        </div>
    );
}



