import React from "react";

const Reclamation = () => {
  return (
    <section id="main-reclamation">
      <div className="left-container">
          <div className="left-container-avatar">
            <div className="speech-bubble">
              <p>
               Oups! Pas de souci. Ça arrive aux meilleurs d'entre nous. Et après tout, on est là pour ça!
              </p>
            </div>

            <div className="avatar">
              <img
                src="img/complete_10.png"
                alt="agp assurance avatar vert homme"
              />
            </div>
          </div>
        <div className="left-container-title">
          <h1>Réclamations</h1>
        </div>
        <div className="left-container-subtitle">
          <p className="desktop">
            Pour effectuer une réclamation, veuillez contacter votre
          </p>
          <p className="desktop">assureur au numéro indiqué.</p>
          <p className="mobile">
            Pour effectuer une réclamation, veuillez contacter votre assureur au
            numéro indiqué.
          </p>
        </div>
      </div>
      <div className="right-container">
        <div className="right-container-intact">
          <img
            className="logo"
            src="img/intact.png"
            alt="Intact logo agp assurance noir"
          />
          <div className="links-connexion-agp links-animation">
            <a href="tel:18664642424">
              <p>1 866 464-2424</p>
              <img src="img/phone_logo.png" alt="agp assurance phone" />
            </a>
          </div>
        </div>

        <div className="right-container-unique">
          <img
            className="logo"
            src="img/unique.png"
            alt="L'Unique logo agp assurance noir"
          />
          <div className="links-connexion-agp links-animation">
            <a href="tel:18004634800">
              <p>1 800 463-4800</p>
              <img src="img/phone_logo.png" alt="agp assurance phone" />
            </a>
          </div>
        </div>

        <div className="right-container-pafco">
          <img
            className="logo"
            src="img/pafco.png"
            alt="Pafco logo agp assurance noir"
          />
          <div className="links-connexion-agp links-animation">
            <a href="tel:18774632727">
              <p>1 877 463-2727</p>
              <img src="img/phone_logo.png" alt="agp assurance phone" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reclamation;
