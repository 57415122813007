import React, {useEffect} from "react";
import Navbar from "../components/Navbars/Navbar";
import Localisation from "../components/Banner/Localisation";
import Footer from "../components/Footers/Footer";
import RendezVous from "../components/Forms/RendezVous";
import Schedules from "../components/Schedules/Schedule";
import {ScheduleProvider} from "../components/Schedules/Context";
// import ReactGA from "react-ga4";

export default function NousJoindre() {

    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname);
    //   }, []);

    return (
        <ScheduleProvider>
            <div className="large-screen-wrapper">
                <div className="main">
                    <Navbar
                        activateSecondClass={true}
                        secondClassName={"main-button-inversed"}
                        tabSelected={5}
                    />
                    <div className="main-container-nous-joindre">
                        <Localisation/>
                        <RendezVous/>
                        {/* <Schedules/> */}
                    </div>
                    <div className="main-footer-nous-joindre">
                        <Footer/>
                    </div>
                </div>
            </div>
        </ScheduleProvider>
    );
}
