import React, { useState, useEffect }  from "react";
import { FacebookShareButton, LinkedinShareButton } from "react-share";

export function ShareButton(props) {
  const [state, setState] = useState({
    open: null,
  });
  
  const [urlFiche, setUrlFiche] = useState();
  useEffect(() => {
    return () => {
      console.log("useEffect");
      if(window.location.href.includes("?fiche=")){
        // console.log("url fiche", window.location.href );
        setUrlFiche(window.location.href)
      }else{
        // console.log("url fiche avec id", `${window.location.href}?fiche=${props.ficheid}`);
        setUrlFiche(`${window.location.href}?fiche=${props.ficheid}`)
      }
    };
  }, []);


  return (
    <div
      className={`share-button ${
        state.open === null ? "" : state.open ? "open" : "hide"
      } main-button-inversed`}
    >
      <div
        onClick={() => {
          setState({
            ...state,
            open: !state.open,
          });
        }}
      >
        <svg
          width={27}
          height={29}
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            d="M21.823 19.237a4.45 4.45 0 0 0-3.532 1.742l-9.614-5.457a4.44 4.44 0 0 0 0-2.893l9.614-5.456a4.454 4.454 0 1 0-.808-1.7l-9.778 5.55a4.457 4.457 0 1 0 0 6.106l9.778 5.55a4.457 4.457 0 1 0 4.34-3.442Zm0-17.36a2.58 2.58 0 1 1 0 5.16 2.58 2.58 0 0 1 0-5.16ZM4.462 16.657a2.58 2.58 0 1 1 0-5.161 2.58 2.58 0 0 1 0 5.16Zm17.36 9.618a2.58 2.58 0 1 1 0-5.16 2.58 2.58 0 0 1 0 5.16Z"
            fill="#000"
          />
        </svg>
      </div>

      <div className={`social-network ${state.open ? "open" : ""}`}>
        <a href="https://www.facebook.com/agpassuranceqc" target="_blank" rel="noreferrer">
          <img alt="Logo de Facebook" src="img/carrieres/facebook-logo.svg" />
        </a>

        <a href="https://www.linkedin.com/company/agp-assurance" target="_blank" rel="noreferrer" >
          <img alt="Logo de LinkedIn" src="img/carrieres/linkedin-logo.svg" />
        </a>

        <a
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(`${window.location.href}?fiche=${props.ficheid}`);
          }}
          href={`${window.location.href}?fiche=${props.ficheid}`}
        >
          <img
            alt="Logo de lien"
            src="img/carrieres/link-logo.svg"
            className="clickboard"
          />
        </a>
      </div>
    </div>
  );
}
