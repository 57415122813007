import React, { useEffect, useState } from "react";
import { AvantagesShowBox } from "./AvantagesShowBox";

const ANIMATION_CLASS = {
  FADE_IN: "fade-in",
  FADE_OUT: "fade-out",
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function AvantageShow({ leftData, rightData }) {
  const [state, setState] = useState({
    left: {
      index: 0,
      animationClass: ANIMATION_CLASS.FADE_IN,
      isRunning: false,
    },
    right: {
      index: 0,
      isRunning: false,
      animationClass: ANIMATION_CLASS.FADE_IN,
    },
  });

  const updateIndex = async () => {
    setState((state) => ({
      ...state,
      left: {
        ...state.left,
        index:
          state.left.index + 1 < leftData.length - 1 ? state.left.index + 1 : 0,
      },
      right: {
        ...state.right,
        index:
          state.right.index + 1 < rightData.length - 1
            ? state.right.index + 1
            : 0,
      },
    }));
  };

  const handleOnMouseEnter = async (e) => {
    setState((currentState) => ({
      ...currentState,
      isRunning: true,
    }));
  };

  const handleOnMouseLeave = async (e) => {
    setState((currentState) => ({
      ...currentState,
      isRunning: false,
    }));
  };

  useEffect(() => {
    setInterval(() => {
      updateIndex();
    }, 5000);
  }, []);

  return (
    <div className="avantages-show">
      {/* <AvantagesShowBox
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        {...state.left}
        avantageData={leftData[state.left.index]}
      /> */}
      <img
        src="img/carrieres/dejeuner.png"
        alt="agp assurance dejeuner"
      />
      {/* <AvantagesShowBox
        {...state.left}
        avantageData={rightData[state.right.index]}
      /> */}
    </div>
  );
}
