import React, { useEffect } from 'react';
import MyRouts from './routers/routes';
import { ModalProvider } from "./contexts/modalContext";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga4";
// CSS imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TRACKING_ID = "G-RQ0M785VCZ";
const YOUR_PIXEL_ID = "537140905205863"
ReactGA.initialize(TRACKING_ID);

export default function App() {

  useEffect(() => {
    ReactPixel.init(YOUR_PIXEL_ID);
    ReactPixel.pageView();
  }, []);

  return (
    <div onScroll={(e) =>{
    }}>
        <ModalProvider>
            <MyRouts />
        </ModalProvider>
    </div>
  );
}