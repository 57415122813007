import React from "react";
import {FIELD_STATUS} from "./Utils";

export function FileField({inputRef, label, required, name, handle, status, placeholder, description, errorMessage, type, fileName, accept, ...CustomInputFieldProps}) {

  return (
    <div className="field file-field main-text-fields">
        <label>
            {required ? (
                <h1>{label}<span className="required"> *</span><span className="description">{description}</span></h1>
              ) : (
                <h1>{label}<span className="description">{description}</span></h1>
              )
            }
        </label>

        <input
          {...CustomInputFieldProps}
          className={status}
          ref={inputRef}
          type="file"
          accept={accept}
        />
        {status === FIELD_STATUS.ERROR ? (<h1 className="error-message">{errorMessage}</h1>) : (<></>)}
        {status === FIELD_STATUS.SUCCESS ? (<h1 className="success-message">{fileName}</h1>) : (<></>)}

    </div>
  );
}



