import React from "react";
import {FIELD_STATUS} from "./Utils";

export default function CheckBoxField({required, name, handle, status, description, errorMessage, ...CustomInputFieldProps}) {


  return (
    <div className="custom-checkbox">

        <input type="checkbox" name={name} {...CustomInputFieldProps} onClick={handle} />
        {status === FIELD_STATUS.ERROR ? (<h1 className="error-message">{errorMessage}</h1>) : (<></>)}

        <label>
            {required ? (
                <p>
                    <span className="description">{description}</span>
                    {/* <span className="required">*</span> */}
                </p>
              ) : (
                <p>
                    <span className="description">{description}</span>
                </p>
              )
            }
        </label>

    </div>
  );
}