import React, {useState} from "react";
import {MainButton} from "../Buttons/Buttons";

const ModalCareer = ({email, message}) => {

  const [display, setDisplay] = useState("")
  return (

    <section className="modal-career" style={{display: display}}>
        <div className="overlay"></div>
        <div className="modal-content">
          {/* <div className="quit" >
            <h1 onClick={() => setDisplay("none")}>x</h1>
          </div> */}
        <div className="modal-title">
            <h2>Merci! Les informations ont bien été enregistrées!</h2>
            <img src="img/complete_9.png" alt="logo agp assurance homme 9" />
        </div>

        {/* <div className="modal-error-message">
            <p>Il semble que vous ayez déjà postulé pour ce poste...</p>
        </div> */}

        <div className="modal-choice">
            <a className="yes" href={"/"}>
            <MainButton name={"RETOURNER À L'ACCUEIL"} />
            </a>
        </div>
        </div>
    </section>
  );
};

export default ModalCareer;
