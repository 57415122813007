import React from "react";

const Politique = () => {
  return (
    <section id="main-politique">
      <div className="main-title">
        <h1>Politique de </h1>
        <h1>confidentialité</h1>
      </div>

      <div className="main-intro">
        <h1>Introduction</h1>
        <p>
          Devant le développement des nouveaux outils de communication, il est
          nécessaire de porter une attention particulière à la protection de la
          vie privée. C'est pourquoi, nous nous engageons à respecter la
          confidentialité des renseignements personnels que nous collectons.
        </p>
      </div>

      <div className="section-title">
        <h1>Collecte des renseignements</h1>
        <h1>personnels</h1>

        <div className="section-subtitle">
          <p>Nous collectons les renseignements suivants :</p>
        </div>

        <ul>
          <li>Nom</li>
          <li>Prénom</li>
          <li>Adresse électronique</li>
          <li>Numéro de téléphone</li>
          <li>Date de renouvellement</li>
        </ul>
        <p>
          Les renseignements personnels que nous collectons sont recueillis au
          travers de formulaires et grâce à l'interactivité établie entre vous
          et notre site Web. Nous utilisons également, comme indiqué dans la
          section suivante, des fichiers témoins et/ou journaux pour réunir des
          informations vous concernant.
        </p>
      </div>

      <div className="section-title">
        <h1>Formulaires et interactivité</h1>

        <div className="section-subtitle">
          <p>
            Vos renseignements personnels sont collectés par le biais de
            formulaire, à savoir :
          </p>
        </div>
        <ul>
          <li>Formulaire d'inscription au site Web</li>
          <li>Concours</li>
        </ul>

        <div className="section-subtitle">
          <p>
            Nous utilisons les renseignements ainsi collectés pour les finalités
            suivantes :
          </p>
        </div>
        <ul>
          <li>Informations / Offres promotionnelles</li>
          <li>Contact</li>
        </ul>

        <div className="section-subtitle">
          <p>
            Vos renseignements sont également collectés par le biais de
            l'interactivité pouvant s'établir entre vous et notre site Web et
            ce, de la façon suivante :
          </p>
        </div>
        <ul>
          <li>Statistiques</li>
          <li>Contact</li>
        </ul>

        <div className="section-subtitle">
          <p>
            Nous utilisons les renseignements ainsi collectés pour les finalités
            suivantes :
          </p>
        </div>
        <ul>
          <li>Forum ou aire de discussion</li>
          <li>Correspondance</li>
          <li>Informations ou pour des offres promotionnelles</li>
        </ul>
      </div>

      <div className="section-title">
        <h1>Fichiers journaux et témoins</h1>

        <div className="section-subtitle">
          <p>
            Nous recueillons certaines informations par le biais de fichiers
            journaux (log file) et de fichiers témoins (cookies). Il s'agit
            principalement des informations suivantes :
          </p>
        </div>

        <ul>
          <li>Adresse IP</li>
          <li>Pages visitées et requêtes</li>
          <li>Heure et jour de connexion</li>
        </ul>

        <div className="section-subtitle">
          <p>Le recours à de tels fichiers nous permet :</p>
        </div>

        <ul>
          <li>Amélioration du service et accueil personnalisé</li>
          <li>Profil personnalisé de consommation</li>
          <li>Statistique</li>
        </ul>
      </div>

      <div className="section-title">
        <h1>Partage des renseignements</h1>
        <h1>personnels</h1>

        <div className="section-subtitle">
          <p>
            Nous nous engageons à ne pas commercialiser les renseignements personnels collectés. 
            Toutefois, il nous arrive de partager ces informations avec nos partenaires dans l'objectif 
            de répondre aux besoins en assurance de nos clients.
          </p>
        </div>

        <p>
          Si vous ne souhaitez pas que vos renseignements personnels soient
          communiqués à des tiers, il vous est possible de vous y opposer au
          moment de la collecte ou à tout moment par la suite, comme mentionné
          dans la section suivante.
        </p>
      </div>

      <div className="section-title">
        <h1>Droit d'opposition et de retrait</h1>

        <div className="section-subtitle">
          <p>
            Nous nous engageons à vous offrir un droit d'opposition et de
            retrait quant à vos renseignements personnels. Le droit d'opposition
            s'entend comme étant la possiblité offerte aux internautes de
            refuser que leurs renseignements personnels soient utilisées à
            certaines fins mentionnées lors de la collecte.
          </p>
        </div>

        <div className="section-subtitle">
          <p>
            Le droit de retrait s'entend comme étant la possiblité offerte aux
            internautes de demander à ce que leurs renseignements personnels ne
            figurent plus, par exemple, dans une liste de diffusion.
          </p>
        </div>

        <div className="section-subtitle">
          <p>Pour pouvoir exercer ces droits, vous pouvez :</p>
        </div>

        <ul>
          <li>Responsable : Maxime Morin-Gagnon</li>
          <li>Titre : Directeur TI</li>
          <li>Courriel : maxime-morin.gagnon@agpassurance.ca</li>
          <li>Téléphone : 18666293686</li>
          <li>Section du site web : www.agpassurance.ca/</li>
          <li>Code postal : 725 boulevard Lebourgneuf, Québec G2J 0C4</li>
        </ul>
      </div>

      <div className="section-title">
        <h1>Droit d'accès</h1>

        <div className="section-subtitle">
          <p>
            Nous nous engageons à reconnaître un droit d'accès et de
            rectification aux personnes concernées désireuses de consulter,
            modifier, voire radier les informations les concernant.
          </p>
        </div>

        <div className="section-subtitle">
          <p>L'exercice de ce droit se fera :</p>
        </div>

        <ul>
          <li>Code postal : 725 boulevard Lebourgneuf, Québec G2J 0C4</li>
          <li>Courriel : maxime-morin.gagnon@agpassurance.ca</li>
          <li>Téléphone : 18666293686</li>
          <li>Section du site web : www.agpassurance.ca/</li>
        </ul>
      </div>

      <div className="section-title">
        <h1>Sécurité</h1>

        <div className="section-subtitle">
          <p>
            Les renseignements personnels que nous collectons sont conservés
            dans un environnement sécurisé. Les personnes travaillant pour nous
            sont tenues de respecter la confidentialité de vos informations.
          </p>
        </div>

        <div className="section-subtitle">
          <p>
            Pour assurer la sécurité de vos renseignements personnels, nous
            avons recours aux mesures suivantes :
          </p>
        </div>

        <ul>
          <li>Gestion des accès - personne autorisée</li>
          <li>Gestion des accès - personne concernée</li>
          <li>Sauvegarde informatique</li>
          <li>Identifiant / mot de passe</li>
        </ul>

        <p>
          Nous nous engageons à maintenir un haut degré de confidentialité en
          intégrant les dernières innovations technologiques permettant
          d'assurer la confidentialité de vos transactions. Toutefois, comme
          aucun mécanisme n'offre une sécurité maximale, une part de risque est
          toujours présente lorsque l'on utilise Internet pour transmettre des
          renseignements personnels.
        </p>
      </div>
    </section>
  );
};

export default Politique;
