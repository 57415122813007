

const Regx = {
  ALPHA:  '^[a-zA-ZÀ-ž_-]+$',
  PHONE_NUMBER : '^[0-9]{10}$',
  EMAIL : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}
  
const testInput = (str, exp) => str === "" ? true : new RegExp(exp).test(str)


export {Regx, testInput}