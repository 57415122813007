import React, {useEffect, useState, useRef} from 'react';
import Slider from "react-slick";
import {useWindowReSize} from "../Hooks/useWindowResize";

function CarouselCard({src, title, authorTitle, authorName, avatar, content, children, defaultValue=false}) {

    const [state, setState] = useState(defaultValue)


    return (
        <div className="carousel-card">
            <div>
                {state ? <div className="carousel-card-content scrollbar">
                    <h3><span>{title}</span> <img className="author-avatar" src={avatar}/></h3>
                    <h4>{authorName}</h4>
                    <h5 className="fade-title">{authorTitle}</h5>
                    {content}
                </div> : <>
                    {children}
                    <img src={src} alt="agp assurance caroussel"/>
                </>}
            </div>

            <div className="carousel-card-footer">
                <h3>{!state ? title  : ""}</h3>
                <a
                    className="link fade-in-underline"
                    onClick={() => {
                    setState(!state)
                }}>LIRE {state ? "MOINS" : "PLUS"}</a>
            </div>
        </div>
    )
}

function NextArrow({onClick}){
    return (<div onClick={onClick} className="carrieres-carousel-arrow">
        <img alt="nextArrow" src="img/carrieres/carousel/next_arrow.png" />
    </div>)
}

function PrevArrow({onClick}){
    return (<div onClick={onClick} className="carrieres-carousel-arrow">
        <img alt="prevArrow"  src="img/carrieres/carousel/prev_arrow.png" />
    </div>)
}

function Carousel({cards, className}){

    const windowSize = useWindowReSize();
    const slider = useRef(undefined)
    const [state, setState] = useState({
        index: 0,
        count: 0
    })

    const nextSlide = () => {
        slider.current.slickNext()
    }

    const prevSlide = () => {
        slider.current.slickPrev()
    }

    useEffect(() =>{
        setState(state)
    }, [windowSize])

    return (
        <div className={className}>
            <Slider
                ref={slider}

                id="carrieres-carousel"
                {
                    ...{
                        dots: false,
                        infinite: false,
                        speed: 500,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        afterChange: (current, next) => {
                            setState({
                                index: current
                            })
                        },
                        responsive: [
                            {
                                breakpoint: 1350,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 1,
                                    infinite: false,
                                    dots: false
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    infinite: false,
                                    dots: false
                                }
                            }
                        ]
                    }
                }>
                { cards }
            </Slider>
            <div className="carrieres-carousel-interface">
                <PrevArrow onClick={prevSlide}/>
                <div className="carrieres-carousel-button">
                    {state.index + 1}/{Math.round(cards.length / slider.current?.innerSlider.props.slidesToShow)}
                </div>
                <NextArrow onClick={nextSlide}/>
            </div>
        </div>
    )
}


export {Carousel, CarouselCard}
