import React, {useEffect} from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import Reclamation from "../components/Banner/Reclamation";
// import ReactGA from "react-ga4";

export default function Reclamations() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  // }, []);

  return (
    <div className="large-screen-wrapper">
      <div className="main">
        <Navbar
          activateSecondClass={true}
          secondClassName={"main-button-inversed"}
          tabSelected={3}
        />
        <div className="main-container-reclamation">
          <Reclamation />
        </div>
        <div className="main-footer-reclamation">
          <Footer />
        </div>
      </div>
    </div>
  );
}
