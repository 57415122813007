import React, {useEffect} from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import Entreprise from "../components/Banner/Entreprise";
import ChoicesEntreprise from "../components/Forms/Choices-entreprise";
import {ScheduleProvider} from "../components/Schedules/Context";
// import ReactGA from "react-ga4";

export default function Entreprises() {
    const newClass = "main-button-inversed";

    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname);
    // }, []);

    return (
        <ScheduleProvider>
            <div className="large-screen-wrapper">
                <div className="main">
                    <Navbar
                        activateSecondClass={true}
                        secondClassName={newClass}
                        tabSelected={2}
                    />
                    <div className="main-container-entreprise">
                        <Entreprise/>
                        <ChoicesEntreprise/>
                    </div>
                    <div className="main-footer-entreprise">
                        <Footer/>
                    </div>
                </div>
            </div>
        </ScheduleProvider>
    );
}
