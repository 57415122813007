import React, { useState } from "react";
import { MainButton } from "../Buttons/Buttons";

export default function Insurances() {
  const colors = ["#5554DB", "#42AAF5", "#057F79", "#CAF456", "#F47628"];

  const defaultColor = "#FCFCFC";

  function baseColor() {
    document.documentElement.style.setProperty(
      "--backgroundColor",
      defaultColor
    );
  }

  function randomColorHabitation() {
    let random = Math.floor(Math.random() * colors.length);
    document.documentElement.style.setProperty(
      "--backgroundColor",
      colors[random]
    );
  }

  function randomColorAutomobile() {
    let random = Math.floor(Math.random() * colors.length);
    document.documentElement.style.setProperty(
      "--backgroundColor",
      colors[random]
    );
  }

  return (
    <section id="main-insurance">
      <div className="choice">
        <div className="subtitle">
          <h2>Obtenez un prix en quelques minutes.</h2>
        </div>

        <div className="title">
          <h1>Quel type d'assurance</h1>
          <h1>recherchez-vous?</h1>
        </div>

        <div className="button-choice">
          <a className="spec-button" href="/particuliers">
            <MainButton name="Particuliers" />
          </a>

          <a className="spec-button" href="/entreprises">
            <MainButton name="Entreprises" />
          </a>
        </div>
      </div>

      <div
        className="type habitation"
        onMouseEnter={randomColorHabitation}
        onMouseLeave={baseColor}
      >
        <div className="container">
          <div className="title">
            <h2>Lien rapide</h2>
          </div>

          <div className="type-circle">
            <img id="house" src="img/house.png" alt="house agp assurance" />
          </div>

          <div className="type-subtitle">
            <h1 style={{ opacity: "0.45" }}>Assurance</h1>
            <h1>habitation</h1>
          </div>

          <div className="ask-soum">
            <a className="fade-in-underline" href="/particuliers#main-form-particulier">
              Demandez une soumission
            </a>
          </div>
        </div>
      </div>

      <div
        className="type automobile"
        onMouseEnter={randomColorAutomobile}
        onMouseLeave={baseColor}
      >
        <div className="container">
          <div className="title">
            <h2>Lien rapide</h2>
          </div>

          <div className="type-circle">
            <img id="car" src="img/car.png" alt="car agp assurance" />
          </div>

          <div className="type-subtitle">
            <h1 style={{ opacity: "0.45" }}>Assurance</h1>
            <h1>auto</h1>
          </div>

          <div className="ask-soum">
            <a href="/particuliers#main-form-particulier">Demandez une soumission</a>
          </div>
        </div>
      </div>
    </section>
  );
}
