import {React, useEffect} from "react";
import { Link } from "react-scroll";

const Particulier = () => {

  useEffect(() => {
    if (window.location.href.includes("main-form-particulier")) {
      document.getElementById("main-form-particulier").scrollIntoView();
    }
  }, [])

  return (
    <section id="main-banner-particulier">
      <div className="title-container-banner-particulier nodrag noselect">
        <h3 className="subtile">Assurance des particuliers</h3>
        <h1 className="title">Une assurance</h1>
        <h1 className="title">qui respecte vos</h1>
        <h1 className="title">particularités.</h1>
        <div className="anchors">
          <Link 
            className="first_achor" 
            to="main-form-particulier"
            href="main-form-particulier"
            smooth={true}
            duration={800}
            offset={-150}
          >
            Demandez une soumission
          </Link>

          <a className="second_achor" href="/nous-joindre#rendez-vous">
            Prenez rendez-vous
          </a>
        </div>
      </div>

      <div className="image-container-banner-particulier">
        <img
          className="image-banner-particulier nodrag"
          src="img/header-particulier.png"
          alt="agp assurance des particuliers"
        />
      </div>

    </section>
  );
};

export default Particulier;
