import React, {useEffect} from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import Politique from "../components/Banner/Politique";
// import ReactGA from "react-ga4";


export default function PolitiqueDeConfidentialite() {

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname);
  // }, []);

  return (
    <div className="large-screen-wrapper">
      <div className="main">
        <Navbar
          activateSecondClass={true}
          secondClassName={"main-button-inversed"}
        />
        <div className="main-container-politique">
          <Politique />
        </div>
        <div className="main-footer-politique">
          <Footer />
        </div>
      </div>
    </div>
  );
}