/**
 * @param {Date} date
 * @param {number} day
 * @param {number} id
 * @param {string} minHour
 * @param {string} maxHour
 * @param {string} note
 * @constructor
 */
export function Schedule({date, day, id, minHour, maxHour, note}) {
    this.date = date;
    this.day = day;
    this.id = id;
    this.minHour = minHour;
    this.maxHour = maxHour;
    this.note = note;
}
