
export const optionsOrigin = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "SPLA (UL)", value: "SPLA (UL)"},
    { key: "2", label: "Facebook", value: "Facebook"},
    { key: "3", label: "Instagram", value: "Instagram"},
    { key: "4", label: "LinkedIn", value: "LinkedIn"},
    { key: "5", label: "Jobillico", value: "Jobillico"},
    { key: "6", label: "Événements scolaires", value: "Événements scolaires"},
    { key: "7", label: "Quelqu'un qui travaille chez AGP", value: "Quelqu'un qui travaille chez AGP"},
    { key: "8", label: "Réseautage", value: "Réseautage"},
    { key: "9", label: "Indeed", value: "Indeed"},
    { key: "10", label: "Glassdoor", value: "Glassdoor"},
];

export const SCHOOL_STATUS = {
    UNIVERSITE : "Université",
    CEGEP : "Cégep",
    NONE: "Pas à l'école"
}

export const optionsSchoolStatus = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "Université", value: SCHOOL_STATUS.UNIVERSITE },
    { key: "2", label: "Cégep ", value: SCHOOL_STATUS.CEGEP },
    { key: "3", label: "Pas à l'école", value: SCHOOL_STATUS.NONE }
];


export const optionsEducationLevel = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "Maîtrise", value: "Maîtrise" },
    { key: "2", label: "MBA", value: "MBA" },
    { key: "3", label: "BAC", value: "BAC" },
    { key: "4", label: "Certificat", value: "Certificat" },
    { key: "5", label: "Autre", value: "Autre" }
];

export const optionsSphereOfStudy = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "Administration", value: "Administration" },
    { key: "2", label: "Orientation", value: "Orientation" },
    { key: "3", label: "Relations industrielles", value: "Relations industrielles" },
    { key: "4", label: "Assurances de dommages", value: "Assurances de dommages" },
    { key: "5", label: "Droit", value: "Droit" },
    { key: "6", label: "Informatique", value: "Informatique" },
    { key: "7", label: "Sciences de la consommation", value: "Sciences de la consommation" },
    { key: "8", label: "Comptabilité", value: "Comptabilité" },
    { key: "9", label: "Multidiciplinaire", value: "Multidiciplinaire" },
    { key: "10", label: "Économie", value: "Économie" },
    { key: "11", label: "Études internationales", value: "Études internationales" },
    { key: "12", label: "Communication", value: "Communication" },
    { key: "13", label: "Enseignement", value: "Enseignement" },
    { key: "14", label: "Programmation", value: "Programmation" },
    { key: "15", label: "Marketing", value: "Marketing" },
    { key: "16", label: "Gestion de commerce", value: "Gestion de commerce" },
    { key: "17", label: "Psychologie", value: "Psychologie" },
    { key: "18", label: "Autre", value: "Autre" },
];

export const optionsHourToCall = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "Toute la journée", value: "Toute la journée" },
    { key: "2", label: "En matinée", value: "En matinée" },
    { key: "3", label: "9h - 10h", value: "9h - 10h" },
    { key: "4", label: "10h - 11h", value: "10h - 11h" },
    { key: "5", label: "11h - 12h", value: "11h - 12h" },
    { key: "6", label: "12h - 13h", value: "12h - 13h" },
    { key: "7", label: "13h - 14h", value: "13h - 14h" },
    { key: "8", label: "14h - 15h", value: "14h - 15h" },
    { key: "8", label: "15h - 16h", value: "15h - 16h" },
    { key: "8", label: "16h - 17h", value: "16h - 17h" }
];

export const optionsFormationAMFContact = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "J'ai complété un diplôme d'étude collégial (54 crédits minimum)", value: "J'ai complété un diplôme d'étude collégial (54 crédits minimum)" },
    { key: "2", label: "Je suis en voie de compléter mon diplôme d'étude collégial", value: "Je suis en voie de compléter mon diplôme d'étude collégial" },
    { key: "3", label: "Je satisfait à un autre critère", value: "Je satisfait à un autre critère" },
];

export const optionsFormationAMFConseiller = [
    { value : "", label: "Choisir", disable: true},
    { key: "1", label: "Je détiens un permis valide en ass. de dommages des particuliers délivré par l'AMF", value: "Je détiens un permis valide" },
    { key: "2", label: "J'ai déjà détenu un permis valide délivré par l'AMF (mais je ne pratique plus)", value: "J'ai déjà détenu un permis valide" },
    { key: "3", label: "Je suis en voie de détenir un permis valide délivré par l'AMF (d'ici quelques mois)", value: "Je suis en voie de détenir un permis valide" },
];
