import React, { useState, useEffect } from "react";
import { MainButton } from "../Buttons/Buttons";
import Soumission from "../Forms/Soumission";

const ChoicesEntreprise = () => {

  const [choices, setChoices] = useState([
    {
      id: 1,
      choice: "Véhicules commerciaux",
      value:"vehicule",
      img: "img/soumissions/camion_soum_ent.jpg",
      className: "img-selection",
      checked: false,
    },
    { 
      id: 2, 
      choice: "Cautionnement", 
      value:"cautionnement",
      img: "img/soumissions/cautionnement.png", 
      className: "img-selection",
      checked: false 
    },
    { 
      id: 3, 
      choice: "Responsabilité civile", 
      value:"responsabilite_civile",
      img: "img/soumissions/lift_soum_ent.jpg", 
      className: "img-selection",
      checked: false 
    },
    { 
      id: 4, 
      choice: "Biens/Entreprises", 
      value:"biens_locataire",
      img: "img/soumissions/biens_soum_ent.png", 
      className: "img-selection",
      checked: false 
    },
    
  ]);

  const selectedChoice = (element, i) => {
    const newArray = choices.map((c) => ({ ...c }));

    if (newArray[i].checked === false) {
      newArray[i].checked = true;
    } else {
      newArray[i].checked = false;
    }

    setChoices(newArray);
  };

  const [nextStep, setNextStep] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [soumissionType, setSoumissionType] = useState("");

  useEffect(() => {
    if(choices.some(e => e.checked === true)) {
      setButtonStatus(true)
    }else{
      setButtonStatus(false)
    }
  }, [choices]);

  function submit (){
    if(buttonStatus) {
      let findOne = choices.find(x => x.checked);
      setSoumissionType(findOne.value)
      setNextStep(true)
    }
  }

  return (

    (nextStep ? (
      <Soumission typeOfInsurance={"commercial"} typeOfSoumission={soumissionType} setNextStep={setNextStep}/>
    ) : (
    <>
      <section id="main-form-entreprise">
        <div className="break-line"/>
        
        <div className="main-form-entreprise-title">
          <div className="main-form-entreprise-title-texte">
            <h1>Que voulez-vous assurer?</h1>
          </div>
          <div className="main-form-entreprise-title-avatar">
            <div className="speech-bubble">
              <p>
                Psssst! Vous obtiendrez un rabais supplémentaire en jumelant plusieurs assurances.
              </p>
            </div>

            <div className="avatar">
              <img
                src="img/complete_10.png"
                alt="agp assurance avatar vert homme"
              />
            </div>
          </div>
        </div>

        <div className="main-form-entreprise-choices">
          {choices.map((element, i) => {
            return (
              <div className="container nodrag">     

                <div 
                  className={element.checked ? "container-circle-checked" : "container-circle"}
                  onClick={() => selectedChoice(element, i)}
                >
                  <img
                    id={element.value}
                    src={element.img}
                    alt="choix agp assurance"
                  />

                  <img
                  className="img-checked"
                  src="img/checked.png"
                  alt="checked"
                  />

                </div> 

                <div className="container-title">
                  <h3>
                    {element.choice}
                  </h3>
                </div>

              </div>
            );
          })}
        </div>

        <div className="main-form-entreprise-select">
          <div className="main-form-entreprise-select-button">
            <MainButton
              name="Prochaine étape"
              handle={submit}
              activateSecondClass={true}
              secondClassName={buttonStatus ? "main-button" : "disabled-button"}
            />
          </div>
        </div>
      </section>
    </>
    ))
  );
};

export default ChoicesEntreprise;
