import React, {useContext, useMemo} from "react";
import {HiArrowNarrowLeft, HiArrowNarrowRight} from "react-icons/hi";
import moment from "moment";
import {Schedule} from "../../contexts/models/Schedule";

const MONTHS = [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre"
]

const WEEK_DAYS = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche"
]

/**
 * @param {Date} date
 * @param {(Date) => Date} setDate
 * @param {[Schedule]} schedules
 * @return {JSX.Element}
 * @constructor
 */
export default function DatePicker({
                                       date = new Date(),
                                       setDate,
                                       schedules=[],
                                       maxDays = 29
                                   }) {

    const selectDate = (selectDate) => {
        setDate(selectDate.toDate())
    }

    const selectTime = (hour, minute) => {
        const newDate = moment(date).set({
            "hour": hour,
            "minute": minute
        });
        setDate(newDate.toDate())
    }

    const nextMonth = () => {
        const newDate = moment(date).add(1, "month")
        const days = getDaysInMonth(newDate).filter(
            date => isIncludeDate(date, schedules, maxDays)
        )

        if(days) {
            const day = days[0]
            setDate(day)
        }
    }

    const prevMonth = () => {
        const newDate = moment(date).subtract(1, "month")
        const days = getDaysInMonth(newDate).filter(
            date => isIncludeDate(date, schedules, maxDays)
        )

        if(days) {
            const day = days[0]
            setDate(day)
        }
    }

    const getDaysFromLastMonth = (currentDate) => {
        const firtstDayOfCurrentMonth = moment(currentDate).startOf("month")
        const firsWeekDayOfCurrentMonth = firtstDayOfCurrentMonth.isoWeekday() - 1

        return Array.from(
            Array(firsWeekDayOfCurrentMonth).keys()
        ).map( (_, i) => firtstDayOfCurrentMonth.clone().subtract({days: i + 1})
        ).reverse()
    }

    const getDaysFromNextMonth = (currentDate) => {
        const lastDayOfCurrentMonth = moment(currentDate).endOf("month")
        const lastWeekDayOfCurrentMonth = 7 - lastDayOfCurrentMonth.isoWeekday()

        return Array.from(
            Array(lastWeekDayOfCurrentMonth).keys()
        ).map(
            (_, i) => lastDayOfCurrentMonth.clone().add({days: i + 1})
        )
    }

    /**
     * @param {Moment} date
     * @return {boolean}
     */
    const isIncludeDate = (date, schedules, maxDays) => date
        && !excludeDatesMap(schedules).has(date.format("YYYY-MM-DD"))
        &&  date.isSameOrAfter(moment(new Date()), "date")
        &&  date.isBefore(moment(new Date()).add({days: maxDays}), "date")


    const getDaysInMonth = (currentDate) => {
        const currentMomentDate = moment(currentDate);
        let daysInMonths = moment(currentDate).daysInMonth()

        return Array.from(
            Array(daysInMonths + 1).keys()).slice(1).map( (day) => {
                return currentMomentDate.startOf('month').clone().add({days: day - 1})
        })

    }

    const getHoursAndMinutesInDayBetween = (minHour, maxHour) => Array.from(
        Array(24).keys()
    ).slice(minHour, maxHour).flatMap(
        hour => Array.from(
            Array(2).keys()
        ).map(i => [hour, i * 30])
    )

    const days = (date) => [
        ...getDaysFromLastMonth(date),
        ...getDaysInMonth(date),
        ...getDaysFromNextMonth(date)
    ].map(momentDate => {
        return ( isIncludeDate(momentDate, schedules, maxDays)
            ? <div className={`day ${moment(date).isSame(momentDate, "date")  ? "selected" : ""}`}
                   key={momentDate.format("YYYY-MM-DD")}
                   onClick={(e) => {
                       selectDate(momentDate)
                   }}>
                {momentDate.date()}
            </div>
            : <div
                key={momentDate.format("YYYY-MM-DD")}
                onClick={(e) => {e.preventDefault()}}
                className={"day disable"}>{momentDate.date()}</div>)
        }
    )

    const hours = (date, minHour, maxHour) => getHoursAndMinutesInDayBetween(minHour, maxHour).map(([hour, minute]) => (
            <li className={`hour ${hour === moment(date).hour() && minute === moment(date).minute() ? "selected" : ""}`}
                onClick={(e) => {
                    selectTime(hour, minute)
                }}>
                {hour} : {minute.toString().padEnd(2, '0')}
            </li>
        )
    )

    const timeMap = (schedules = []) => new Map(schedules.map(schedule => {
        return [moment(schedule.date).format("YYYY-MM-DD"), schedule]
    }));

    /**
     * @param {[Schedule]} schedules
     * @param {Date} date
     * @return {Schedule}
     */
    const minHour = (schedules = [], date) => {
        return parseInt(
            timeMap(schedules).get(moment(date).format("YYYY-MM-DD"))?.minHour?.slice(0, 2)
        )
    }

    /**
     * @param {[Schedule]} schedules
     * @param {Date} date
     * @return {Schedule}
     */
    const maxHour = (schedules = [], date) => parseInt(
        timeMap(schedules).get(moment(date).format("YYYY-MM-DD"))?.maxHour?.slice(0, 2)
    )

    /**
     * @param {[Schedule]} schedules
     * @return {Map<string, Schedule>>} schedules
     */
    const excludeDatesMap = (schedules) => timeMap(schedules.filter(schedule => {
        return (schedule.minHour === null || schedule.maxHour === null);
        }
    ))

    const showNextMonthButton = () => {
        const maxMonth = moment(new Date()).add(maxDays, "days").month()
        return moment(date).add(1, "month").month() == maxMonth;
    }

    const showPrevMonthButton = () => {
        const minMonth = moment(new Date()).month()
        return moment(date).subtract(1, "month").month() == minMonth;
    }

    return (
        <div className="date-picker-container">
            <div className="dates">
                <div className="months">
                    <div className="months-selection">
                        <div className="left-arrow">
                            {
                                showPrevMonthButton()
                                    ? <HiArrowNarrowLeft onClick={prevMonth}/>
                                    : ""
                            }
                        </div>
                        <div className="current-currentMonth">
                            {MONTHS[moment(date).month()]} {moment(date).year()}
                        </div>
                        <div className="right-arrow"  >
                            {
                                showNextMonthButton()
                                    ? <HiArrowNarrowRight onClick={nextMonth}/>
                                    : ""
                            }
                        </div>
                    </div>

                    <div className="week-days-selection">
                        <ul className="week-days-selection-list">
                            {WEEK_DAYS.map(day => <li>{day.slice(0, 2).toLowerCase()}</li>)}
                        </ul>
                    </div>
                </div>

                <div className="dates-selection">
                    {days(date)}
                </div>
            </div>

            <div className="hours">
                <div className="title">
                    <div className="title-hours">Heures</div>
                </div>
                <div className="hours-details">
                    <ul className="hours-ul">
                        {hours(date, minHour(schedules, date), maxHour(schedules, date))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
