import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./styles/index.scss";
// import './styles/Fonts/factorA/factorA.css'
// import './styles/Fonts/neueHaasUnica/neueHaasUnica.css'
import CookieConsent from "react-cookie-consent";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CookieConsent
            location="bottom"
            cookieName="agpassurance_biscuit"
            containerClasses="redcolorbanner"
            style={{ 
              boxShadow: "rgb(0, 0, 0) 8px 8px 0px -1px, rgb(255, 255, 255) 8px 8px", 
              background: "#FCFCFC", 
              height: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "justify"
            }}
            
            buttonText="Accepter"
            contentStyle={{color: "#111111"}}
            buttonStyle={{ 
              color: "#FCFCFC", 
              fontSize: "24px", 
              boxShadow: "rgb(0, 0, 0) 8px 8px 0px -1px, rgb(255, 255, 255) 8px 8px",
              backgroundColor: "#C7A0F9",
              height: "50px",
              width: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            overlay={true}
          >
            <span style={{ fontSize: "18px", fontFamily: "factor-a-light" }}>
              Nous utilisons des témoins et des technologies similaires pour améliorer votre expérience
              de navigation, contrôler le trafic et les performances de notre site. <br></br>Pour en savoir plus sur 
              l'utilisation des témoins et sur la manière dont nous traitons vos données personnelles, consultez 
              notre <a href="/politique-de-confidentialite" style={{textDecoration: "underline"}}>Politique de confidentialité</a>.
            </span>
          </CookieConsent>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
