import React, {useState, useEffect, useContext} from "react";
import {MainButton} from "../Buttons/Buttons";
import { ModalContext } from "../../contexts/modalContext";

const Modal = () => {

  // Declare a state variable with a default value of 'none'
  const {setSubmissionModal } = useContext(ModalContext)

  const handleClick = () => {
    setSubmissionModal(false)
  };

  return (

    <section className="modal-ab">
        <div className="overlay"></div>
        <div className="modal-content">
          <div className="quit" >
            <h1 onClick={handleClick}>x</h1>
          </div>
        <div className="modal-title">
            <h2>De quel type d'assurances avez-vous besoin?</h2>
        </div>

        <div className="modal-showcase">
            <img src="img/complete_9.png" alt="logo agp assurance homme 9" />
        </div>

        <div className="modal-choice">
          <a className="yes" href="/particuliers#main-form-particulier">
            <MainButton name={"Particuliers"}/>
          </a>
          {/* <HashLink className="yes" to="/particuliers#main-form-particulier">
            <MainButton name={"Particuliers"}/>
          </HashLink> */}

          <a className="yes" href="/entreprises#main-form-entreprise">
            <MainButton name={"Entreprises"}/>
          </a>

          {/* <HashLink className="no" to="/entreprises#main-form-entreprise">
            <MainButton name={"Entreprises"}/>
          </HashLink> */}

        </div>
        </div>
    </section>
  );
};

export default Modal;
