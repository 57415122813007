import React from "react";
import {FIELD_STATUS} from "./Utils";

export default function InputTextField({label, required, name, handle, status, placeholder, description, errorMessage, type, ...CustomInputFieldProps}) {


  return (
    <div className="field main-text-fields">
        <label>
            {required ? (
                <h1>{label}<span className="required"> *</span><span className="description">{description}</span></h1>
              ) : (
                <h1>{label}<span className="description">{description}</span></h1>
              )
            }
        </label>

        <input className={status} type={type} name={name} placeholder={placeholder} {...CustomInputFieldProps} onClick={handle} />
        {status === FIELD_STATUS.ERROR ? (<h1 className="error-message">{errorMessage}</h1>) : (<></>)}
    </div>
  );
}



