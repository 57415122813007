import React from "react";

export function MainButton({
  name,
  handle,
  disabled,
  activateSecondClass,
  secondClassName,
  ...CustomButtonProps
}) {
  return (
    <div
      {...CustomButtonProps}
      className={`main-button noselect ${
        activateSecondClass ? secondClassName : ""
      }`}
      onClick={handle}
    >
      <h1>{name}</h1>
    </div>
  );
}


export function LoadingButton() {
  return (
    <div className={'main-button noselect disabled-button'}> 
      <div className="dot-pulse"></div>
    </div>
  );
}
