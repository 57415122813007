import React, {useEffect} from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import Particulier from "../components/Banner/Particulier";
import ChoicesParticulier from "../components/Forms/Choices-particulier";
import {ScheduleProvider} from "../components/Schedules/Context";
// import ReactGA from "react-ga4";


export default function Particuliers() {

    // useEffect(() => {
    //     ReactGA.pageview(window.location.pathname);
    //   }, []);

  return (
    <ScheduleProvider>
        <div className="large-screen-wrapper">
            <div className="main">
                <Navbar
                    activateSecondClass={true}
                    secondClassName={"main-button-inversed"}
                    tabSelected={1}
                />
                <div className="main-container-particulier">
                    <Particulier/>
                    <ChoicesParticulier/>
                </div>
                <div className="main-footer-particulier">
                    <Footer/>
                </div>
            </div>
        </div>
    </ScheduleProvider>);
}
