import React, { useState,useEffect } from "react";
import { MainButton } from "../Buttons/Buttons";
import Soumission from "../Forms/Soumission";

const ChoicesParticulier = () => {

  const [choices, setChoices] = useState([
    { 
      id: 1, 
      choice: "Auto", 
      value:"vehicule",
      checked: false, 
      img: "img/soumissions/auto_soum_part.jpg", 
      className: "img-selection-car-part",
    },
    { 
      id: 2, 
      choice: "Habitation", 
      value:"habitation",
      checked: false,
      img: "img/soumissions/maison_soum_part.jpg", 
      className: "img-selection",
    },
    { 
      id: 3, 
      choice: "Bateau", 
      value:"bateau",
      checked: false, 
      img: "img/soumissions/bateau_soum_part.jpg", 
      className: "img-selection",
    },
    { 
      id: 4, 
      choice: "Biens/Locataires", 
      value:"biens_locataire",
      checked: false, 
      img: "img/soumissions/biens_soum_part.jpg", 
      className: "img-selection",
    },
    { 
      id: 5, 
      choice: "Récréatif", 
      value:"recreatif",
      checked: false, 
      img: "img/soumissions/recreatif_part.jpg", 
      className: "img-selection",
    },
  ]);
  
  const selectedChoice = (element, i) => {
    const newArray = choices.map((c) => ({ ...c }));
    if (newArray[i].checked === false) {
      newArray[i].checked = true;
    } else {
      newArray[i].checked = false;
    }
    setChoices(newArray);
  };
  const [nextStep, setNextStep] = useState(false); // Parent
  const [soumissionType, setSoumissionType] = useState();
  const [buttonStatus, setButtonStatus] = useState(false);

  useEffect(() => {
    if(choices.some(e => e.checked === true)) {
      let findOne = choices.find(x => x.checked);
      setSoumissionType(findOne.value)
      setButtonStatus(true)
    }else{
      setButtonStatus(false)
    }
  }, [choices]);

  function submit (){
    if(buttonStatus) {
      setNextStep(true)
    }
  }


  return (

    (nextStep ? (
      <Soumission typeOfInsurance={"particulier"} typeOfSoumission={soumissionType} setNextStep={setNextStep}/>
    ) : (
    <>
      <section id="main-form-particulier">

        <div className="main-form-particulier-title">
          <div className="main-form-particulier-title-texte">
            <h1>Que voulez-vous assurer?</h1>
          </div>
          <div className="main-form-particulier-title-avatar">
            <div className="speech-bubble">
              <p>
                Psssst! Vous obtiendrez un rabais supplémentaire en jumelant plusieurs assurances.
              </p>
            </div>

            <div className="avatar">
              <img
                src="img/complete_10.png"
                alt="agp assurance avatar vert homme"
              />
            </div>
          </div>
        </div>

        <div className="main-form-particulier-choices">
          {choices.map((element, i) => {
            return (
              <div className="container nodrag">     

                <div 
                  className={element.checked ? "container-circle-checked" : "container-circle"}
                  onClick={() => selectedChoice(element, i)}
                >
                  <img
                    id={element.value}
                    src={element.img}
                    alt="choix agp assurance"
                  />

                  <img
                  className="img-checked"
                  src="img/checked.png"
                  alt="checked"
                  />

                </div> 

                <div className="container-title">
                  <h3>
                    {element.choice}
                  </h3>
                </div>

              </div>
            );
          })}
        </div>

        <div className="main-form-particulier-select">
          <div className="main-form-particulier-select-button">
            <MainButton
              name="Prochaine étape"
              handle={submit}
              activateSecondClass={true}
              secondClassName={buttonStatus ? "main-button" : "disabled-button"}
            />
          </div>
        </div>
      </section>
    </>
    ))

  );
};

export default ChoicesParticulier;
