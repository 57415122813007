export const AvantagesData = [
    {
        title: "Assurances collectives",
        content: "Incluant les soins médicaux complémentaires.",
        src: "img/carrieres/stetos.png",
        color: "#5554DB"
    },
    {
        title: "Programme de télémédecine",
        content: "Un accès gratuit et illimité à des professionnels de la santé 24/7, sans attente et sans déplacement.",
        src: "img/carrieres/telemed.png",
        color: "#CAF456"
    },
    {
        title: "Bonus de performance",
        content: "En plus de toucher une prime à la fin du mois, plus tu performes, plus tu as de chance de remporter les tirages!",
        src: "img/carrieres/cash_envelope.png",
        color: "#FF8F3B"
    },
    {
        title: "Télétravail",
        content: "Tu pourras concilier télétravail et présence au bureau. Le meilleur des deux mondes!",
        src: "img/carrieres/laptop.png",
        color: "#42AAF5"
    },
    {
        title: "Plan mieux-être",
        content: "Parce qu'on a à cœur ton bien-être, tu peux recevoir jusqu'à 800$ pour rembourser certaines dépenses liées à ton bonheur!",
        src: "img/carrieres/cadeau.png",
        color: "#FF8F3B"
    },
    {
        title: "Horaire et conciliation travail/étude",
        content: "Tu changes d'horaire trois fois par an? Ce n'est pas un problème, on s'adapte à ta réalité et tes besoins. Ne t'en fais pas, les quarts de soir sont limités.",
        src: "img/carrieres/cycle.png",
        color: "#42AAF5"
    },
    {
        title: "Avancement et formation continue",
        content: "Chez AGP, ton statut d'étudiant n'est pas un frein. Avance quand même rapidement dans la compagnie.",
        src: "img/carrieres/diplome.png",
        color: "#CAF456"
    },
    {
        title: "Photo portrait professionnel",
        content: "Afin de t'accompagner dans le début de ta carrière, nous t'offrons une séance photo professionnelle et te donnons une photo portrait que tu pourras réutiliser sur tes réseaux.",
        src: "img/carrieres/photo.png",
        color: "#CAF456"
    },
    {
        title: "Ambiance et bureau moderne",
        content: "Envie de t'amuser au travail ? On est un peu comme le 'Google de l'assurance'. Pas besoin de refaire ta garde-robe, viens travailler confortablement dans tes vêtements de tous les jours.",
        src: "img/carrieres/desk.png",
        color: "#CAF456"
    },
    {
        title: "Régime d'épargne collectif",
        content: "Participe au REER collectif et obtient une partie des bénéfices de l'entreprise via notre avantageux programme RPDB.",
        src: "img/carrieres/cash.png",
        color: "#CAF456"
    },
    {
        title: "Pub AGP",
        content: "Non non tu ne rêves pas! On t'offre une bière gratuitement pour bien commencer ton weekend.",
        src: "img/carrieres/pub_agp.png",
        color: "#CAF456"
    },
]
