import {Carousel, CarouselCard} from "./Carousel";

function getExtrasCarouselCards() {
    return [<CarouselCard
        key="1"
        title={ <>
                Rouge et Or
            </>
        }
        authorName="Par Vincent Lavigne."
        authorTitle="Conseiller en assurance"
        src="img/carrieres/carousel/slide_1.png"
        avatar="img/carrieres/face_2.svg"
        content="

            Je termine actuellement non seulement ma dernière année d'étude universitaire, 
            mais également celle de l'écusson du Rouge et Or de l'Université Laval. 
            Pendant mon parcours, j'ai eu droit à une flexibilité exemplaire de la part de mon employeur, 
            qui tenait à ce que je vive mes passions (à fond!). 
            
            Que ce soit pour mes nombreuses pratiques, 
            mes entraînements, mes matchs, les gala, etc, j'ai toujours eu le soutien de mes collègues et 
            accès à un horaire qui ''fittait'' parfaitement dans mon agenda chargé. 
            Je remercie AGP pour cette belle conciliation qui m'a permis d'avoir une gestion 
            de mon horaire de travail, d'étude et de sport vraiment saine.

        "
    >

</CarouselCard>, <CarouselCard
        key="2"
        title={"Partie à l'aventure"}
        authorName="Par Dali-Jade C."
        authorTitle="Agente de contact"
        src="img/carrieres/carousel/slide_3.png"
        avatar="img/carrieres/face_1.svg"
        content="
            Je suis passionnée de voyage et de découverte. J'oserais même me qualifier ''d'aventurière'' 
            et ça, mon employeur l'a compris! En effet, grâce à AGP, j'ai pu concrétiser certains projets 
            des plus épanouissants, car mes supérieurs m'ont épaulé pour les rendre réalisables. Ayant visité 
            Ibiza, Miami, Mikonos en moins d'une année, je crois que c'est une chance extraordinaire que j'ai 
            de pouvoir voyager aux quatres coins du globe, et ce, en grande partie grâce à la compréhension et 
            à la flexibilité de mon employeur. Mon expérience chez AGP assurance m'a permis de m'épanouir dans 
            plusieurs sphères de ma vie, et tout ça, dans un environnement professionnel et enrichissant. 
            Pour moi, AGP est un exemple parfait d'une vision moderne et conciliante. J'ai vécu des expériences 
            qui me seront chères toute ma vie et pour tout ça je vous dit : merci APG! 
        "
    >

    </CarouselCard>, <CarouselCard
        key="3"
        title="Le grand saut"
        authorName="Par Antoine Bernier."
        authorTitle="Conseiller en assurance"
        src="img/carrieres/carousel/slide_2.png"
        avatar="img/carrieres/face_2.svg"
        content="
            Je suis quelqu'un qui n'a pas froid aux yeux et qui aime les sensations fortes donc,
            sauter en parachute est quelque chose qui m'a toujours attiré. J'irais même jusqu'à
            dire que c'était un rêve pour moi. En octobre dernier, grâce à mes performances dans
            mon travail, je me suis qualifié pour courir la chance de gagner un saut en parachute…
            Et j'ai finalement gagné!

            Je me souviens encore du sourir que j'avais quand on m'a annoncé que je gagnais le prix.
            Le plus beau là dedans, c'est pas seulement d'avoir réalisé un rêve grâce à AGP, mais c'est
            de l'avoir fait en compagnie de mon grand frère, qui lui est venu sauter avec moi en solo.
            Ce fût vraiment une expérience sensationnelle!
        "
    />


    ]
}

export function Extras() {
    return (
        <Carousel cards={getExtrasCarouselCards()}/>
    )
}
