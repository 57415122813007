import React from "react";
import {MainButton} from "../Buttons/Buttons";
import moment from "moment";

const Confirmation = ({firstName, dateTime, email, buttonName, linkTo}) => {

  const options = { month: 'long', day: 'numeric', hour:'numeric', minute: 'numeric'};
  let rdv = `${moment(dateTime).format("YYYYMMDDTHHmm00")}/${moment(dateTime).add(1, 'hours').format("YYYYMMDDTHHmm00")}`

  return (
    <section id="confirmation">

      <div className="left-confirmation">

        <div className="slogan">
          <h1>Votre demande</h1>
          <h1>a été envoyée<span style={{color: "#42aaf5"}}> {firstName} !</span></h1>
          {email? <p>Vous devriez avoir reçu une confirmation à <span>{email}</span>.</p> :<></>}
        </div>

        <a className="button-zone" href={linkTo}>
          <MainButton name={buttonName}/>
        </a>
      </div>

      <div className="right-confirmation">
        <div className="slogan">
          <h1>{dateTime.toLocaleDateString("fr-FR", options)}</h1>
        </div>

        {email? 
            <a 
              className="line"
              href={`https://calendar.google.com/calendar/r/eventedit?text=Rendez-vous+-+AGP+Assurance&dates=${rdv}`}
              target="_blank"
              rel="noreferrer"
            >
              Ajouter au calendrier
            </a>
          :
            <></>
        }

        <a className="button-zone" href={linkTo}>
          <MainButton name={buttonName}/>
        </a>

      </div>

      <div className="button-tablet-zone">
        <a className="button-tablet" href="/">

          <MainButton name={buttonName}/>

        </a>
      </div>


    </section>
  );
};

export default Confirmation;
