import React, { useState, createContext} from 'react';


const ModalContext = createContext();

const ModalProvider = ({children}) => {

    const [submissionModal, setSubmissionModal] = useState(false);
    const [careerModal, setCareerModal] = useState(false);

    return (

        <ModalContext.Provider value={{submissionModal, setSubmissionModal, careerModal, setCareerModal}}>
            {children}
        </ModalContext.Provider>
    )
}

export { ModalContext, ModalProvider};