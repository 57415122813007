import React, { useState, useEffect, useContext } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { SelectField } from "../Fields/SelectField";
import InputTextField from "../Fields/InputTextField";
import CheckBoxField from "../Fields/CheckBoxField";
import { MainButton, LoadingButton } from "../Buttons/Buttons";
import Confirmation from "../Banner/Confirmation";
import DatePicker from "../Datepicker/Datepicker";
import { ApiClient } from "../../contexts/apiContext";
import { Regx, testInput } from "../Global/regx";
import moment from "moment";
import { ScheduleContext } from "../Schedules/Context";

const Soumission = ({ typeOfInsurance, typeOfSoumission, setNextStep }) => {
  const scheduleContext = useContext(ScheduleContext);

  const [rdvDate, setRdvDate] = useState(new Date());
  const [firstName, setFirstName] = useState({
    value: "",
    required: true,
    status: "",
    name: "firstName",
    placeholder: "",
    description: "",
    errorMessage: "Le prénom n'est pas valide",
    type: "text",
  });
  const [lastName, setLastName] = useState({
    value: "",
    required: true,
    status: "",
    name: "lastName",
    placeholder: "",
    description: "",
    errorMessage: "Le nom n'est pas valide",
    type: "text",
  });
  const [phone, setPhone] = useState({
    value: "",
    required: true,
    status: "",
    name: "phone",
    placeholder: "",
    description: "",
    errorMessage: "Le numéro de téléphone n'est pas valide",
    type: "tel",
  });
  const [police, setPolice] = useState({
    value: "",
    required: false,
    status: "",
    name: "police",
    placeholder: "",
    description: " (client seulement)",
    errorMessage: "Le numéro de police n'est pas valide",
    type: "text",
  });
  const [email, setEmail] = useState({
    value: "",
    required: true,
    status: "",
    name: "email",
    placeholder: "",
    description: "",
    errorMessage: "Le courriel n'est pas valide",
    type: "text",
  });
  const [insuranceType, setInsuranceType] = useState({
    value: typeOfInsurance,
    required: true,
    status: "",
    name: "insuranceType",
    placeholder: "",
    description: "",
    errorMessage: "Vous devez choisir une option",
    type: "text",
  });
  const [soumissionType, setSoumissionType] = useState({
    value: typeOfSoumission,
    required: true,
    status: "",
    name: "soumissionType",
    placeholder: "",
    description: "",
    errorMessage: "Vous devez choisir une option",
    type: "text",
  });

  const [politique, setPolitique] = useState({
    value: false,
    required: true,
    status: "",
    name: "politique",
    placeholder: "",
    description: "",
    errorMessage: "Vous devez accepter les conditions.",
    type: "checkbox",
  });

  const [goToPolitique, setGoToPolitique] = useState(
    <p>
        En soumettant ce formulaire, vous confirmez que vous avez lu et accepté la <a href={"/politique-de-confidentialite"} 
        style={{color: "black", fontFamily:"factor-a-regular", textUnderlineOffset:"5px"}} rel="noreferrer" target="_blank">
        Politique de confidentialité</a>. <span className="required">*</span>
    </p>
  );

  const [buttonStatus, setButtonStatus] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    let status = [
      firstName.status,
      lastName.status,
      phone.status,
      email.status,
      politique.status,
    ];
    if (status.includes("error") || status.includes("")) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
    return () => {};
  }, [firstName, lastName, phone, rdvDate, email, politique]);

  function submit() {
    if (buttonStatus) {
      setLoadingButton(true);

      const formData = new FormData();
      formData.append("first_name", firstName.value);
      formData.append("last_name", lastName.value);
      formData.append("email", email.value);
      formData.append("phone_number", phone.value);
      formData.append(
        "callback_date",
        moment(rdvDate).format("YYYY-MM-DD HH:mm:ss")
      );
      formData.append("insurance_type", insuranceType.value);
      formData.append("soum_type", soumissionType.value);

      ApiClient.sendLead(formData)
        .then((res) => {
          setSuccess(true);
          setTimeout(() => {
            setLoadingButton(false);
          }, 2000);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }

  return (
    <>
      {success ? (
        <Confirmation
          firstName={firstName.value}
          dateTime={rdvDate}
          email={email.value}
          buttonName="Retour à l'accueil"
          linkTo="/"
        />
      ) : (
        <section id="soumission">
          <div className="top">
            <div className="title">
              <h1>Parlons-en de vive voix.</h1>
            </div>

            <div className="avatar-speech">
              <div className="speech-bubble triangle">
                <p>
                  On y presque, on a juste besoin des informations suivantes.
                </p>
              </div>

              <div className="avatar">
                <img
                  src="img/complete_9.png"
                  alt="agp assurance avatar vert femme"
                />
              </div>
            </div>
          </div>

          <div className="bottom">
            <div className="date-picker">
              <div className="date-picker-label">
                <p>
                  Choisissez le moment qui vous convient le mieux et un
                  conseiller prendra contact avec vous.{" "}
                  <span style={{ color: "#f47628" }}>*</span>
                </p>
              </div>

              <DatePicker
                date={rdvDate}
                setDate={setRdvDate}
                schedules={
                  typeOfInsurance === "commercial"
                    ? scheduleContext.schedulesEntreprise
                    : scheduleContext.schedulesAP
                }
              />
            </div>

            <div className="rdv-form">

              <div className="row1">

                <div className="left-form">
                  <InputTextField
                    label="Prénom"
                    name={firstName.name}
                    required={firstName.required}
                    status={firstName.status}
                    placeholder={firstName.placeholder}
                    description={firstName.description}
                    errorMessage={
                      firstName.status === "error" ? firstName.errorMessage : ""
                    }
                    type="text"
                    maxLength="64"
                    onChange={(e) => {
                      e.target.value !== ""
                        ? testInput(e.target.value, Regx.ALPHA)
                          ? setFirstName({
                              ...firstName,
                              value: e.target.value,
                              status: "success",
                            })
                          : setFirstName({
                              ...firstName,
                              value: e.target.value,
                              status: "error",
                            })
                        : setFirstName({
                            ...firstName,
                            value: e.target.value,
                            status: "",
                          });
                    }}
                  />
                </div>

                <div className="right-form">
                  <InputTextField
                    label="Nom"
                    name={lastName.name}
                    required={lastName.required}
                    status={lastName.status}
                    placeholder={lastName.placeholder}
                    description={lastName.description}
                    errorMessage={
                      lastName.status === "error" ? lastName.errorMessage : ""
                    }
                    type="text"
                    maxLength="64"
                    onChange={(e) => {
                      e.target.value !== ""
                        ? testInput(e.target.value, Regx.ALPHA)
                          ? setLastName({
                              ...lastName,
                              value: e.target.value,
                              status: "success",
                            })
                          : setLastName({
                              ...lastName,
                              value: e.target.value,
                              status: "error",
                            })
                        : setLastName({
                            ...lastName,
                            value: e.target.value,
                            status: "",
                          });
                    }}
                  />
                </div>

              </div>

              <div className="row2">
                <InputTextField
                  label="Téléphone"
                  name={phone.name}
                  required={phone.required}
                  status={phone.status}
                  placeholder={phone.placeholder}
                  description={phone.description}
                  errorMessage={
                    phone.status === "error" ? phone.errorMessage : ""
                  }
                  type="tel"
                  maxLength="10"
                  onChange={(e) => {
                    e.target.value !== ""
                      ? testInput(e.target.value, Regx.PHONE_NUMBER)
                        ? setPhone({
                            ...phone,
                            value: e.target.value,
                            status: "success",
                          })
                        : setPhone({
                            ...phone,
                            value: e.target.value,
                            status: "error",
                          })
                      : setPhone({
                          ...phone,
                          value: e.target.value,
                          status: "",
                        });
                  }}
                />
              </div>

              <div className="row2">
                <InputTextField
                  label="Adresse courriel"
                  name={email.name}
                  required={email.required}
                  status={email.status}
                  placeholder={email.placeholder}
                  description={email.description}
                  errorMessage={
                    email.status === "error" ? email.errorMessage : ""
                  }
                  type="text"
                  maxLength="320"
                  onChange={(e) => {
                    e.target.value !== ""
                      ? testInput(e.target.value, Regx.EMAIL)
                        ? setEmail({
                            ...email,
                            value: e.target.value,
                            status: "success",
                          })
                        : setEmail({
                            ...email,
                            value: e.target.value,
                            status: "error",
                          })
                      : setEmail({
                          ...email,
                          value: e.target.value,
                          status: "",
                        });
                  }}
                />
              </div>

              <div className="check-zone">
                <CheckBoxField 
                  description={goToPolitique}
                  required={politique.required}
                  errorMessage={ politique.status === "error" ? politique.errorMessage : ""}
                  onChange={(e) => { e.target.checked === true ? 
                    setPolitique({
                        ...politique,
                        value: e.target.checked,
                        status: "success",
                      })
                    : setPolitique({
                        ...politique,
                        value: e.target.checked,
                        status: "",
                      });
                  }}
                />
              </div>

            </div>
          </div>

          <div className="send-zone">
            <div
              className="back-button noselect"
              onClick={() => {
                setNextStep(false);
              }}
            >
              <div className="back-arrow">
                <div className="arrow">
                  <img src="img/back_arrow.png" alt="" />
                </div>
                <h1>Précédent</h1>
              </div>
            </div>

            <div className="button">
              {loadingButton ? (
                <LoadingButton />
              ) : (
                <MainButton
                  name="Envoyer"
                  handle={submit}
                  activateSecondClass={true}
                  secondClassName={
                    buttonStatus ? "main-button-inversed" : "disabled-button"
                  }
                />
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Soumission;
