import React, {useEffect} from "react";
import { Link } from "react-scroll";

const Entreprise = () => {

  useEffect(() => {
    if (window.location.href.includes("main-form-entreprise")) {
      document.getElementById("main-form-entreprise").scrollIntoView();
    }
  }, [])

  return (
    <section id="main-banner-entreprise">
      <div className="title-container-banner-entreprise nodrag noselect">
        <h3 className="subtile">Assurance des entreprises</h3>
        <h1 className="title">On assure </h1>
        <h1 className="title">vos ambitions.</h1>
        <div className="anchors">
          <Link 
            className="first_achor" 
            to="main-form-entreprise"
            href="main-form-entreprise"
            smooth={true}
            duration={800}
            offset={-150}
          >
            Demandez une soumission
          </Link>

          <a className="second_achor" href="/nous-joindre#rendez-vous">
            Prenez rendez-vous
          </a>
        </div>
      </div>

      <div className="image-container-banner-entreprise">
        <img
          className="image-banner-entreprise nodrag"
          src="img/header-entreprises.png"
          alt="agp assurance des entreprises"
        />
      </div>
      
      <div className="break-line"/>

    </section>
  );
};

export default Entreprise;
