import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbars/Navbar";
import Footer from "../components/Footers/Footer";
import { Avantages } from "../components/Carrieres/Avantages";
import { TypeAnimation } from "react-type-animation";
import { MainButton } from "../components/Buttons/Buttons";
import { Extras } from "../components/Carrieres/Extras";
import { Fiche } from "../components/Carrieres/Fiche";
import { AvantageShow } from "../components/Carrieres/AvantageShow";
import { AvantagesData } from "../components/Carrieres/AvantagesData";
// import { Link } from "react-scroll";
// import ReactGA from "react-ga4";


const FICHE_NAME = {
  CC: "CC",
  AP: "AP",
};

const FICHES = {
  CC: {
    tag: "Agent de contact",
    ficheid: "01",
    title: "Agent de contact",
    lieu: "Québec et de la maison",
    horaire: "Temps plein, temps partiel",
    salaire: "24,50$/H",
    salairedescription: "",
    bonification: "",
    desc: (
      <>
        <p style={{ fontFamily: "factor-a-light" }}>
          Le poste d'agent de contact consiste à récolter les dates de
          renouvellement de l'assurance automobile et habitation de nos clients
          potentiels.
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          À titre de premier contact, les employés du Centre contact sont
          responsables d'établir un lien de confiance avec la clientèle, teinté
          de jovialité et de professionnalisme. Il s'agit du premier maillon
          d'un important travail à la chaîne.
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          Après seulement quelques mois, de nouvelles fonctions vont s'offrir à
          toi. En t'impliquant au support client, notamment en répondant à des
          questions techniques, administratives et de perception, tu seras dans
          la bonne voie pour progresser au sein de l'entreprise. Tout ça, bien
          sûr, avec toujours une rémunération des plus compétitives!
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          Cet emploi va marquer le début de ta carrière chez AGP Assurance. Tu
          pourrais rapidement décrocher une promotion vers notre équipe en
          assurance de dommages des particuliers. Tu ne possèdes pas ton permis?
          Pas de problème, nous t'offrons un programme de certification
          personnalisé et rémunéré pour rapidement décrocher ton permis de
          l'Autorité des marchés financiers (AMF).
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>C'est beaucoup plus qu'un boulot, c'est une opportunité à saisir.</p>

        <p style={{ fontFamily: "factor-a-light" }}>Envoie-nous ta candidature : il ne manque que toi!</p>

        <p style={{ fontFamily: "factor-a-bold" }}>Exigences : </p>

        <ul className="dashed" style={{ fontFamily: "factor-a-light" }}>
          <li style={{ fontFamily: "factor-a-bold" }}> Tu dois détenir (ou être en voie de le compléter dans la prochaine année) l'équivalent d'un 
            Diplôme d'études collégiales (54 unités collégiales) ou une attestation d'emploi à temps plein de 3 ans.
          </li>

          <li style={{ fontFamily: "factor-a-light" }}> Si ce n'est pas le cas, tu dois répondre aux exigences des équivalences de formation délivrées par 
            l'Autorité des marchés financiers (AMF).
          </li>

          <li style={{ fontFamily: "factor-a-light" }}> Être disponible à travailler un minimum de 3 journées par semaine.</li>

          <li style={{ fontFamily: "factor-a-light" }}> Faire preuve de dynamisme.</li>

          <li style={{ fontFamily: "factor-a-light" }}> Posséder une bonne capacité de communication; Aimer travailler en équipe.</li>

          <li style={{ fontFamily: "factor-a-light" }}> Être mobilisé(e) par les objectifs de performance.</li>
        </ul>
      </>
    ),
  },

  AP: {
    tag: "Conseiller particulier",
    ficheid: "02",
    title: "Conseiller en assurance particulier",
    lieu: "Québec et de la maison",
    horaire: "Temps plein, temps partiel",
    salaire: "30$ à 33$/H",
    salairedescription: "avant bonification",
    bonification: "Mensuelle",
    desc: (
      <>
        <p style={{ fontFamily: "factor-a-light" }}>
          Tu en dis quoi si on t'offre un emploi avec (enfin) de vrais défis ?
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          Notre équipe de conseillers aux ventes est responsable d'offrir une
          expérience hors du commun à nos clients potentiels et actuels.
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          D'abord et avant tout, les conseillers sont aux premières loges au
          niveau du développement des affaires. Ils ont comme principal mandat
          d'offrir une soumission d'assurance à leurs clients, auprès des plus
          grands joueurs dans le réseau de courtage au Québec.
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          Dans un deuxième temps, un conseiller est responsable de la gestion du
          service à la clientèle. Ce volet étant large (selon les besoins et les
          demandes de nos clients), ça en fait un mandat qui diversifie le
          travail de manière intéressante.
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          En terminant, les conseillers se voient attribués des tâches connexes
          à l'assurance, élargissant ainsi l'éventail de responsabilités.
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          Tu vois à moyen terme ? Cet emploi pourrait être le début de ta
          carrière chez AGP. En misant sur tes compétences, on pourrait t'offrir
          de réelles opportunités d'avancement.
        </p>

        <p style={{ fontFamily: "factor-a-light" }}>
          <span style={{ fontFamily: "factor-a-bold" }}>Important</span> : Ce
          poste exige que tu détiennes un permis valide en assurance de dommages
          des particuliers délivré par l'Autorité des marchés financiers (AMF).
        </p>
      </>
    ),
  },
};

const FICHES_DATA = {
  "01": FICHES[FICHE_NAME.CC],
  "02": FICHES[FICHE_NAME.AP],
};

export default function Carrieres() {
  const [state, setState] = useState({
    showFiche: false,
    fiches: FICHES.CC,
  });

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    // ReactGA.pageview(window.location.pathname);

    const ficheid = params.get("fiche");

    if (ficheid && ficheid in FICHES_DATA) {
      setState({
        showFiche: true,
        data: FICHES_DATA[ficheid],
      });
    }
  }, []);

  const middleAvantageDataIndex = Math.ceil(AvantagesData.length / 2);

  return (
    <div className="large-screen-wrapper">
      <div className="main">
        <div className="main-container">
          <Navbar
            activateSecondClass={true}
            secondClassName="main-button-inversed"
            tabSelected={4}
          />
          <div data-scroll-section id="page-carriere" className="wrapper">
            {state.showFiche ? (
              <Fiche
                {...state.data}
                handleBack={() => {
                  setState({
                    ...state,
                    showFiche: false,
                  });
                }}
                {...state.data}
              />
            ) : (
              <div>
                <section id="carrieres-top">
                  <div>
                    <h2>
                      <span >Travailler</span> chez AGP c'est
                      <span className="secondary-dark-orange">
                        <TypeAnimation
                          sequence={[
                            "surprenant",
                            1000,
                            "le fun",
                            2000,
                            "différent",
                            1500,
                            "du bon monde",
                            3000,
                            "compétitif",
                          ]}
                          speed={45}
                          wrapper="span"
                          cursor={true}
                          repeat={Infinity}
                          style={{ fontSize: "1em" }}
                        />
                      </span>
                    </h2>
                  </div>

                  <AvantageShow
                    leftData={AvantagesData.slice(0, middleAvantageDataIndex)}
                    rightData={AvantagesData.slice(middleAvantageDataIndex)}
                  />
                </section>

                <section id="carrieres-jobs">
                  <h2>
                    Offres d'emploi <span className="indice">({Object.keys(FICHES_DATA).length})</span>
                  </h2>
                  <ul>
                    <li data-fiche-id="1">
                      <div>
                        Agent de contact{" "}
                      </div>
                      <span>Québec</span>
                      <MainButton
                        handle={() => {
                          setState({
                            showFiche: true,
                            data: FICHES[FICHE_NAME.CC],
                          });
                          window.scrollTo(0, 0);
                        }}
                        name="POSTULER"
                      />
                    </li>

                    <li data-fiche-id="1">
                      <div>
                        Conseiller en assurance{" "}
                      </div>
                      <span>Québec</span>
                      <MainButton
                        handle={() => {
                          setState({
                            showFiche: true,
                            data: FICHES[FICHE_NAME.AP],
                          });
                          window.scrollTo(0, 0);
                        }}
                        name="POSTULER"
                      />
                    </li>
                  </ul>
                </section>

                <section id="carrieres-msg">
                  <h2>
                    On fait confiance à l'équipe comme aucune autre entreprise ne
                    peut le faire.
                  </h2>
                  <div className="msg">
                    <p>#Power to the people</p>
                  </div>
                </section>

                <section id="carrieres-extra">
                  <h2>Nos histoires de passion</h2>
                  <Extras />
                </section>

                <section id="carrieres-avantages">
                  <Avantages />
                </section>

                
              </div>
            )}
          </div>
        </div>
        <div className="main-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
