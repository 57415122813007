const FIELD_STATUS = {
    SUCCESS: "success",
    ERROR: "error"
}

function onChange(value, setValue, testInput = null) {
    let status = ""

    if(value !== "") {
        if(!testInput || testInput(value)) {
            status = "success"
        } else {
            status = "error"
        }
    }

    setValue(oldValue => {
        return {
            ...oldValue,
            value: value,
            status: status
        }
    })
}

function select(value, field, setField) {
    setField(oldValue => ({
        ...oldValue,
        value: value
    }))
}

function hasExtension(fileName, exts) {
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
}

export {FIELD_STATUS, select, onChange, hasExtension}
