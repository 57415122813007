import { useEffect, useRef } from "react";
import { Carousel, CarouselCard } from "./Carousel";
import { AvantagesData } from "./AvantagesData";

export const AvantageRow = ({ title, content, src }) => {
  return (
    <li className="avantages-row">
      <div>
        <img src={src} />
      </div>
      <div>
        <h3>{title}</h3>
        <p>{content}</p>
      </div>
    </li>
  );
};

function ScrollWrapper({ children }) {
  const sticky = useRef(null);
  const horizontal = useRef(null);
  const spaceHolder = useRef(null);

  useEffect(() => {
    spaceHolder.current.style.height = `${
      horizontal.current.scrollWidth - horizontal.current.scrollWidth / 11
    }px`;

    const handleScroll = (event) => {
      if (sticky.current && sticky.current != null) {
        horizontal.current.style.transform = `translateX(-${sticky.current.offsetTop}px)`;
      }
    };

    const handleResize = (event) => {
      spaceHolder.current.style.height = `${
        horizontal.current.scrollWidth - horizontal.current.scrollWidth / 4
      }px`;
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="horizontal-container">
      <div ref={spaceHolder} className="space-holder">
        <div ref={sticky} className="sticky">
          <div ref={horizontal} className="horizontal">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export function Avantages() {
  return (
    <>
      <ScrollWrapper>
        <ul className="avantages-rows">
          <li>
            <h2>
              {" "}
              <span>AGP, c'est très avantageux</span>
              <div>
                <div className="msg">
                  <p>#DE VRAIS AVANTAGES</p>
                </div>
              </div>
            </h2>
          </li>
          {AvantagesData.map((row) => (
            <AvantageRow {...row} />
          ))}
        </ul>
      </ScrollWrapper>
        <div className="msg avantages">
            <p>#DE VRAIS AVANTAGES</p>
        </div>
      <h2 className="avantages"> AGP, c'est très avantageux</h2>


      <Carousel
        className="avantages-carousel"
        cards={AvantagesData.map((row, index) => (
          <CarouselCard
            key={index}
            content={<AvantageRow {...row} />}
            defaultValue={true}
          />
        ))}
      />
    </>
  );
}
