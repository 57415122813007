import React, { useState, useContext, useEffect } from "react";
import { MainButton } from "../Buttons/Buttons";
import Modal from "../Modal/modal";
import { Cross as Hamburger } from "hamburger-react";
import { ModalContext } from "../../contexts/modalContext";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';


const Navbar = ({ activateSecondClass, secondClassName, tabSelected }) => {
  const { submissionModal, setSubmissionModal } = useContext(ModalContext)

  const [mobileMenu, setMobileMenu] = useState(false);
  const buttonClass = "main-button-inversed-nav-mobile";
  const assetNavs = [
    {
      id: 1,
      name: "Particuliers",
      path: "/particuliers",
      dotted: false,
    },
    {
      id: 2,
      name: "Entreprises",
      path: "/entreprises",
      dotted: false,
    },
    {
      id: 3,
      name: "Réclamations",
      path: "/reclamations",
      dotted: false,
    },
    {
      id: 4,
      name: "Carrières",
      path: "/carrieres",
      dotted: false,
    },
    {
      id: 5,
      name: "Nous joindre",
      path: "/nous-joindre",
      dotted: false,
    },
  ];

  const toggleModal = () => {
    setSubmissionModal(true)
  };

  const openHamMenu = () => {
    mobileMenu
      ? document.documentElement.style.setProperty("--showHamMenu", "none")
      : document.documentElement.style.setProperty("--showHamMenu", "block");
    setMobileMenu(!mobileMenu);
  };

  mobileMenu ? disableBodyScroll(document) : enableBodyScroll(document)
  

  return (
    <nav>
      <div className="main-container-navbar">
        <div className="nav-logo-container">
          <a href="/">
            <img
              src="img/agp_logo_md croped.png"
              alt="Logo AGP Assurance MD"
              width="135"
            />
          </a>
        </div>

        <div className="nav-links-container">
          {assetNavs.map((data) => {
            return (
              <a
                className={tabSelected === data.id ? "underline" : "none"}
                href={data.path}
              >
                {data.name}
              </a>
            );
          })}
        </div>

        <div className="nav-button-soumission">
          <a href="/nous-joindre#rendez-vous">
            <MainButton
                name="RENDEZ-VOUS"
                activateSecondClass={activateSecondClass}
                secondClassName={secondClassName}
              />   
          </a>      
        </div>

        <div className="ham-menu" onClick={() => openHamMenu()}>
          <Hamburger size={48} duration={0.8} />
        </div>
      </div>
      
      <div className="ham-container">
        <div className="ham-nav-links-container">
          <a className="particuliers" href="/particuliers">Particuliers</a>
          <a className="entreprises" href="/entreprises">Entreprises</a>
          <a className="reclamations" href="/reclamations">Réclamations</a>
          <a className="carrieres" href="/carrieres">Carrières</a>
          <a className="nous-joindre" href="/nous-joindre">Nous joindre</a>
        </div>
        <div className="ham-nav-button-container-soumission">
          <a href="/nous-joindre#rendez-vous">
            <MainButton
                name="RENDEZ-VOUS"
                activateSecondClass={true}
                secondClassName={buttonClass}
              />
          </a>
        </div>
      </div>

      {submissionModal && <Modal />}
    </nav>
  );
};

export default Navbar;